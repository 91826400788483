import { useLocation } from 'react-router-dom';
import { decrypt } from 'utils/cipher';
import qs from 'qs';
import queryString from 'query-string';

export function useQuery() {
  const search = useLocation().search;
   const _query = queryString.parse(search, {
     arrayFormat: 'index',
   });

  if (!_query?.token) {
    return { ..._query, verified: false };
  }
  const decryptToken = decrypt(_query.token, 'oneclasscool');
  const decryptObject = qs.parse(JSON.parse(decryptToken));
  const nextQuery = { ..._query, ...decryptObject, verified: true };

  return nextQuery;
}